.Tile {
  width: auto;
  min-width: 220px;
  max-width: 320px;
  text-align: left;
  margin: 12px;
  padding: 24px 32px;
  display: inline-block;
  position: relative;
  box-shadow: 0 5px 15px #00000014;
}

.Tile:hover {
  box-shadow: 3px 5px 25px #b3030394;
}

.Tile .header {
  color: #222;
  margin-bottom: 18px;
  font-size: 24px;
}

.Tile .body {
  min-height: 48px;
  color: #333;
  margin-bottom: 32px;
  font-style: italic;
  display: block;
}

@media (max-width: 600px) {
  .Tile {
    width: 46%;
    min-width: auto;
    min-height: 150px;
    margin: 4px;
    padding: 12px;
    display: inline-block;
  }

  .Tile .header {
    margin-bottom: 8px;
    font-size: 18px;
  }

  .Tile .body {
    min-height: 64px;
    margin-bottom: 12px;
    font-size: 14px;
    display: block;
  }
}

/*# sourceMappingURL=index.51e26d94.css.map */
