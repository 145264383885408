.Tile {
    position: relative;
    display: inline-block;
    margin: 12px;
    width: auto;
    min-width: 220px;
    max-width: 320px;
    /* min-height: 205px; */
    padding: 24px 32px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
    text-align: left;
    /* opacity: 0.98s; */
}
.Tile:hover {
    box-shadow: 3px 5px 25px rgba(179, 3, 3, 0.58);
    /* opacity: 1; */
}

.Tile .header {
    font-size: 24px;
    color: #222;
    margin-bottom: 18px;
}

.Tile .body {
    display: block;
    min-height: 48px;
    margin-bottom: 32px;
    color: #333;
    font-style: italic;
}


@media (max-width: 600px) {
    .Tile {
        display: inline-block;
        width: 46%;
        min-width: auto;
        padding: 12px;
        min-height: 150px;
        /* max-width: 96%; */
        /* margin: 16px auto; */
        margin: 4px;
    }
    .Tile .header {
        font-size: 18px;
        margin-bottom: 8px;
    }
    .Tile .body {
        display: block;
        min-height: 64px;
        margin-bottom: 12px;
        font-size: 14px;
    }
}